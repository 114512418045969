
.App {
  font-family: sans-serif;
  text-align: center;
  border-radius: 3px;
}
body{margin:0} 

.Form {
  padding: 20px;
  border-radius: 1px;
}
.CoverImage {
  padding: 20px;
}
.App {
  background-color: white;
}